import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';

const ProjectPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { modules, seo, location, title, pageFooter } = page || {};

  console.log(page);

  return (
    <Layout pageFooter={pageFooter}>
      <PageMeta {...seo} />
      <div className="px-gutter pb-20">
        <h1 className="isH2 text-[50px] mb-one">{title}</h1>
        <h4 className="font-h-medium">{location}</h4>
      </div>
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default ProjectPage;

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    page: sanityProject(slug: { current: { eq: $slug } }) {
      title
      location
      seo {
        ...SEO
      }
      pageFooter {
        ... on SanityTextFooter {
          key: _key
          _type
          title
          footerText: _rawFooterText(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityImageFooter {
          key: _key
          _type
          text: _rawText(resolveReferences: { maxDepth: 10 })
          bgImage {
            ...ImageWithPreview
          }
        }
        ... on SanityMapFooter {
          key: _key
          _type
          headline: _rawHeadline(resolveReferences: { maxDepth: 10 })
          text: _rawText(resolveReferences: { maxDepth: 10 })
          image {
            ...ImageWithPreview
          }
        }
        ... on SanityGridTextFooter {
          key: _key
          _type
          title
          items {
            _key
            title
            text: _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
      }
      modules {
        ...PageModules
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const ImageText = loadable(() => import('./modules/imageText'));
const ImageBanner = loadable(() => import('./modules/imageBanner'));
const SingleColText = loadable(() => import('./modules/singleColText'));
const TwoColText = loadable(() => import('./modules/twoColText'));
const IconCarousel = loadable(() => import('./modules/iconCarousel'));
const GradientBanner = loadable(() => import('./modules/gradientBanner'));
const CircleBanner = loadable(() => import('./modules/circleBanner'));
const StickyChartSection = loadable(() => import('./modules/stickyChartSection'));
const SolarDiagram = loadable(() => import('./modules/solarDiagram'));
const BioGrid = loadable(() => import('./modules/bioGrid'));
const AccordionSet = loadable(() => import('./modules/accordionSet'));
const PageNav = loadable(() => import('./modules/pageNav'));
const ImageBlock = loadable(() => import('./modules/imageBlock'));
const VideoBlock = loadable(() => import('./modules/videoBlock'));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case 'imageBanner':
        return <ImageBanner {...item} />;
      case 'imageText':
        return <ImageText {...item} />;
      case 'singleColText':
        return <SingleColText {...item} />;
      case 'twoColText':
        return <TwoColText {...item} />;
      case 'iconCarousel':
        return <IconCarousel {...item} />;
      case 'gradientBanner':
        return <GradientBanner {...item} />;
      case 'circleBanner':
        return <CircleBanner {...item} />;
      case 'stickyChartSection':
        return <StickyChartSection {...item} />;
      case 'solarDiagram':
        return <SolarDiagram {...item} />;
      case 'bioGrid':
        return <BioGrid {...item} />;
      case 'accordionSet':
        return <AccordionSet {...item} />;
      case 'pageNav':
        return <PageNav {...item} />;
      case 'imageBlock':
        return <ImageBlock {...item} />;
      case 'videoBlock':
        return <VideoBlock {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
